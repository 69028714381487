.salaryForm {
    font-size: 90%;
}
.salaryForm input {
    background: #ffc;
}

td.withInput {
    padding: 6px 12px !important;
}
td.withInput > input {
    height: calc(1.9rem + 2px);
}

input.narrowField {
    width: 100px;
}



.salaryCalcTable td {
    text-align: right;
}
.salaryCalcTable td.withInput > input {
    margin: 0 0 0 auto;
    text-align: right;
}
.salaryCalcTable td.centered {
    text-align: center;
}
.salaryCalcTable td.centered.withInput > input {
    text-align: center;
}
