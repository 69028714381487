.dropzone {
    padding: 1em;

    border: 1px solid #ced4da;
    border-radius: 6px;

    background: #f1f6f7;
}

.dropzone.filled {
    background: transparent;    
}

.dropzone.error {
    background: #fccac8;    
}

.dropzone.active {
    background: #f1f1a7;
}

.dropzone small {
    color: #888;
}

.dropzone .row {
    margin-top: 0.5em;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.dropzone .icon {
    /* font-size: 120%; */
    margin-right: 0.3em;

    color: #007bff;
}

.dropzone .title {
    flex-grow: 1;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropzone .loading {
    font-style: italic;
    color: #888;
    font-size: 90%;
    margin-top: 0.5em;
}
