/*!
 * main v1.0
 * Copyright 2020 The Rokus Authors
 */
.table-responsive{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}