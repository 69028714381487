.rankTable .table-success {
    font-weight: bold;
    font-size: 120%;
}

.rankTable thead tr:last-child th {
    padding-bottom: 2em;
}

.rankTable .star {
    width: 0;
    padding-right: 0;
    font-size: 190%;
    vertical-align: middle;
    color: #f6bb06;
    text-shadow: 0 1px 6px rgba(0,0,0,0.4), 0 0 3px rgba(255,0,0,0.5);
}
